<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2"></font></div></td>
          </tr>
          <tr>
            <td><div align="left">
              <p align="left">We aim to obtain qualitatively correct estimates of
g, the coefficient of the HG phase function, for different
contaminants. The following image shows our setup, which is similar to the
setup for measuring <router-link to="/repository/Contaminants/texture">optical
thickness patterns</router-link>, except in two places. First, we use a laser instead
of a projector. Second, we use a uniform contaminant layer. 
We first measure the thickness of this layer. When
the laser beam hits the contaminants, part of it will scatter
towards the Lambertian board and generate a falloff (or lobe)
pattern on the board. The center region will have a very strong
spike due to the attenuated laser, while the brightness of other
regions is due to the scattering. From this falloff image, we can
fit g through optimization.

              
                </p><div align="center"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/setup.jpg">
                      <br>
                  </div>
                <h3 align="left">Copyright</h3>
                <div align="left">The database is the property of Columbia University. This data can only be used for research or  academic purposes. Any commercial use of the data whatsoever or incorporation of the data into a  larger database intended for public distribution must be done with the explicit written consent of  CAVE administrators. <br>
                  </div>
                <h3 align="left">Download</h3>
              <p align="left">
The database contains 12 samples, including various kinds of contaminations, plus
one example with clean glass which is used as a simple verification. 
The images of the falloff patterns are high dynamic range and in EXR format. For more information about EXR, visit <a href="http://www.openexr.com">OpenEXR</a>
please. Moreover, we also show the plots of the fitting, in
which the blue curves are the fit and the red circles are the measurements. 


</p><p>Click each of the sample in the following table to download. Also, the 12 samples are zipped and
can be downloaded together <a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/raw-exr.zip">here (46MB)</a>.
We provide some Matlab code for reading and writing EXR files, as well as code for estimating g <router-link to="/repository/Contaminants/source">here</router-link>. </p>

              <p align="left"> </p>
              
                <div align="left">

<center><table border="2">
	<tbody><tr>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/0441_hdrimg_powder.exr">0441_hdrimg_powder.exr</a><br>g=0.825200<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/0441_hdrimg_powder.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/0441_hdrimg_powder.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/0441_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/0441_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7185_hdrimg_powder.exr">7185_hdrimg_powder.exr</a><br>g=0.777100<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7185_hdrimg_powder.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7185_hdrimg_powder.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7185_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7185_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7137_hdrimg_soap_deposit.exr">7137_hdrimg_soap_deposit.exr</a><br>g=0.895000<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7137_hdrimg_soap_deposit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7137_hdrimg_soap_deposit.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7137_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7137_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG01_hdrimg_soap.exr">IMG01_hdrimg_soap.exr</a><br>g=0.863100<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG01_hdrimg_soap.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG01_hdrimg_soap.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG01_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG01_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
	</tr>
	<tr>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7220_hdrimg_smudges.exr">7220_hdrimg_smudges.exr</a><br>g=0.937300<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7220_hdrimg_smudges.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7220_hdrimg_smudges.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7220_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7220_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7273_hdrimg_fingerpints.exr">7273_hdrimg_fingerpints.exr</a><br>g=0.943000<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7273_hdrimg_fingerpints.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7273_hdrimg_fingerpints.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7273_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7273_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG03_hdrimg_fingerprint.exr">IMG03_hdrimg_fingerprint.exr</a><br>g=0.891600<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG03_hdrimg_fingerprint.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG03_hdrimg_fingerprint.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG03_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG03_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7201_hdrimg_salt_deposit.exr">7201_hdrimg_salt_deposit.exr</a><br>g=0.741400<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7201_hdrimg_salt_deposit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7201_hdrimg_salt_deposit.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7201_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7201_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
	</tr>
	<tr>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7343_hdrimg_sand.exr">7343_hdrimg_sand.exr</a><br>g=0.782700<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7343_hdrimg_sand.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7343_hdrimg_sand.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7343_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7343_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG02_hdrimg_detergent.exr">IMG02_hdrimg_detergent.exr</a><br>g=0.831200<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG02_hdrimg_detergent.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG02_hdrimg_detergent.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG02_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/IMG02_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/newIMG01_hdrimg_clay.exr">newIMG01_hdrimg_clay.exr</a><br>g=0.681200<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/newIMG01_hdrimg_clay.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/newIMG01_hdrimg_clay.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/newIMG01_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/newIMG01_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
		<td align="center"><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7337_hdrimg_clean.exr">7337_hdrimg_clean.exr</a><br>g=0.984000<br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7337_hdrimg_clean.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7337_hdrimg_clean.thumb.png" height="150"></a><br><a href="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7337_g_fit.png"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/scattering/7337_g_fit.thumb.png" height="150"></a><br>&nbsp;</td>
	</tr>
</tbody></table></center>



                </div>
            </div></td>
          </tr>
          <tr>
            <td><hr>
              <router-link to="/repository/Contaminants">Database Home</router-link><br>
Contact:&nbsp;<a href="mailto:jwgu@cs.columbia.edu">jwgu@cs.columbia.edu</a><br>
Last modified: 06/02/2007</td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>